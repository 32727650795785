import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Tweezer from "tweezer.js";
import useWindowDimensions from "../../hooks/use-windows-dimensions.hook";

function useScrollSnap({ ref = null, duration = 100, delay = 50 }) {
  const isActiveInteractionRef = useRef(null);
  const scrollTimeoutRef = useRef(null);
  const currentScrollOffsetRef = useRef(null);
  const targetScrollOffsetRef = useRef(null);
  const animationRef = useRef(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const location = useLocation();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const tickAnimation = useCallback((value) => {
    const scrollTopDelta =
      targetScrollOffsetRef.current - currentScrollOffsetRef.current;
    const scrollTop =
      currentScrollOffsetRef.current + (scrollTopDelta * value) / 10000;
    window.scrollTo({ top: scrollTop, behavior: "smooth" });
  }, []);

  const resetAnimation = useCallback(() => {
    currentScrollOffsetRef.current = window.pageYOffset;
    targetScrollOffsetRef.current = 0;
    animationRef.current = null;
  }, []);

  const endAnimation = useCallback(() => {
    if (!animationRef.current) return;
    animationRef.current.stop();
    resetAnimation();
  }, [resetAnimation]);

  // Modified from https://stackoverflow.com/a/125106
  const getElementsInView = useCallback(() => {
    if (ref.current == null) return;
    const elements = [].slice.call(ref.current.children); // Need to convert HTMLCollection to native JS Array
    return elements.filter((element) => {
      let top = element.offsetTop;
      const height = element.offsetHeight;
      while (element.offsetParent) {
        element = element.offsetParent;
        top += element.offsetTop;
      }
      return (
        top < window.pageYOffset + window.innerHeight &&
        top + height > window.pageYOffset
      );
    });
  }, [ref]);

  const getTargetScrollOffset = useCallback((element) => {
    let top = element.offsetTop;
    while (element.offsetParent) {
      element = element.offsetParent;
      top += element.offsetTop;
    }
    return top;
  }, []);

  const snapToTarget = useCallback(
    (target) => {
      if (animationRef.current) {
        animationRef.current.stop();
      }

      const elements = [].slice.call(ref.current.children);
      elements.forEach((element, index) => {
        if (element.isSameNode(target)) {
          setScrollIndex(index);
        }
      });

      targetScrollOffsetRef.current = getTargetScrollOffset(target);
      animationRef.current = new Tweezer({
        start: 0,
        end: 10000,
        duration: duration,
      });

      animationRef.current.on("tick", tickAnimation);
      animationRef.current.on("done", resetAnimation);

      animationRef.current.begin();
    },
    [ref, duration, getTargetScrollOffset, tickAnimation, resetAnimation]
  );

  const findSnapTarget = useCallback(() => {
    const deltaY = window.pageYOffset - currentScrollOffsetRef.current;
    // if (deltaY == 0) return;
    currentScrollOffsetRef.current = window.pageYOffset;
    const elementsInView = getElementsInView();
    if (!elementsInView) return;
    if (elementsInView.length < 2)
      if (elementsInView[0] != null) snapToTarget(elementsInView[0]);
    if (deltaY > 0) {
      if (elementsInView[1] != null) snapToTarget(elementsInView[1]);
    } else {
      if (elementsInView[0] != null) snapToTarget(elementsInView[0]);
    }
  }, [getElementsInView, snapToTarget]);

  const onInteractionStart = useCallback(() => {
    endAnimation();
    isActiveInteractionRef.current = true;
  }, [endAnimation]);

  const onInteractionEnd = useCallback(() => {
    isActiveInteractionRef.current = false;
    findSnapTarget();
  }, [findSnapTarget]);

  const onInteraction = useCallback(() => {
    endAnimation();
    if (scrollTimeoutRef) clearTimeout(scrollTimeoutRef.current);
    if (isActiveInteractionRef.current || animationRef.current) return;

    scrollTimeoutRef.current = setTimeout(findSnapTarget, 500);
  }, [endAnimation, findSnapTarget]);

  useEffect(() => {
    window.scrollTo({ top: windowHeight * 0, behavior: "smooth" });
  }, [location, windowHeight]);

  useEffect(() => {
    if (ref) {
      resetAnimation();

      document.addEventListener(
        "scroll",
        function (event) {
          // Clear our timeout throughout the scroll
          if (scrollTimeoutRef) clearTimeout(scrollTimeoutRef.current);
          if (isActiveInteractionRef.current || animationRef.current) return;

          // Set a timeout to run after scrolling ends
          scrollTimeoutRef.current = setTimeout(function () {
            // Run the callback
            if (windowWidth > 768) findSnapTarget();
          }, 500);
        },
        false
      );

      return () => {
        endAnimation();
      };
    }
  }, [
    ref,
    resetAnimation,
    findSnapTarget,
    endAnimation,
    onInteractionStart,
    onInteractionEnd,
    onInteraction,
    windowWidth,
  ]);

  return scrollIndex;
}

export default useScrollSnap;
