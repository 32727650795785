import { Box, Flex, Image, Text, useTheme } from "@chakra-ui/react";
import React from "react";

import useWindowDimensions from "../../../hooks/use-windows-dimensions.hook";

type MainProps = {
  children?: React.ReactNode;
};

const Compensi: React.FC<MainProps> = () => {
  const theme = useTheme();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const isSmall = (windowWidth || 0) < 768;

  return (
    <Flex
      padding={{
        base: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        sm: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        md: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[40]}`,
        lg: `${theme.space[24]} 0 0 250px`,
        xl: `${theme.space[24]} 0 0 225px`,
      }}
      direction={{ base: "column", lg: "column" }}
    >
      <Flex direction={{ base: "column", lg: "row" }} justifyContent={"center"}>
        <Flex
          width={{ base: "100%", lg: "50%" }}
          height={{
            base: "auto",
            lg: `calc((${windowHeight}px/2) - ${theme.space[24]}/2)`,
          }}
          color={theme.colors.white}
          direction={"row"}
          justifyContent={{ md: "center", lg: "right" }}
        >
          <Flex
            direction={"column"}
            width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
            margin={"auto 0 auto 0"}
          >
            <Text
              // width={"380px"}
              fontSize={{
                base: theme.fontSizes["3xl"],
                lg: theme.fontSizes["4xl"],
                xl: theme.fontSizes["5xl"],
              }}
              lineHeight={{
                base: theme.lineHeights.shorter,
                lg: theme.lineHeights.none,
              }}
              fontWeight={theme.fontWeights.bold}
              padding={{
                base: `0 0 ${theme.space[6]} 0`,
                lg: `${theme.space[12]} 0 ${theme.space[8]} 0`,
              }}
            >
              Compensi migliori
              <br hidden={isSmall} /> cliccatori
            </Text>
            <Text
              fontSize={{
                base: theme.fontSizes["sm"],
                lg: theme.fontSizes["sm"],
                xl: theme.fontSizes["sm"],
              }}
              padding={{
                base: 0,
                lg: `0 0 0 0`,
              }}
            >
              (in base alla velocità di invio del progetto sul totale dei
              cliccatori
              <br hidden={isSmall} /> di ATS – CONSULENTI ASSOCIATI S.r.l.)
            </Text>
          </Flex>
        </Flex>
        <Flex
          width={{ base: "100%", lg: "50%" }}
          height={{
            base: "auto",
            lg: `calc((${windowHeight}px/2) - ${theme.space[24]}/2)`,
          }}
          color={theme.colors.white}
          direction={"row"}
          justifyContent={{ md: "center", lg: "left" }}
        >
          <Flex
            direction={"column"}
            width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
            margin={"auto 0 auto 0"}
            display={{ sm: "none", lg: "flex" }}
          >
            <Box textAlign={"center"}>
              <Image
                src="/assets/stars.svg"
                alt="Hand"
                margin={{
                  base: `${theme.space[24]} 0`,
                  lg: 0,
                  sm: `${theme.space[24]} auto`,
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        width={{ base: "100%", lg: "100%" }}
        height={{
          base: "auto",
          lg: `calc((${windowHeight}px/2) - ${theme.space[24]}/2)`,
        }}
        color={theme.colors.white}
        direction={"row"}
        justifyContent={"center"}
      >
        <Flex
          direction={{ sm: "column", lg: "row" }}
          justifyContent={"center"}
          width={{ lg: "830px", md: "500px", sm: "380px" }}
          marginLeft={{ lg: "-170px", md: "0" }}
        >
          <Flex
            minWidth={{ base: "33%", lg: "33%" }}
            direction={"column"}
            textAlign={"center"}
          >
            <Text
              fontWeight={"semibold"}
              fontSize={{
                base: theme.fontSizes["3xl"],
                sm: "130px",
                md: "200px",
              }}
              lineHeight={"200px"}
              padding={{
                base: 0,
                lg: `0 0 0 0`,
              }}
              color={theme.colors.brand.accent}
            >
              1
            </Text>
            <Image
              src="/assets/star2.svg"
              alt="Hand"
              position={{ base: "absolute" }}
              display={{ sm: "block", lg: "none" }}
              right={"0"}
              marginRight={"25%"}
            />
            <Text
              fontSize={{
                base: theme.fontSizes["sm"],
                lg: theme.fontSizes["sm"],
                xl: theme.fontSizes["md"],
              }}
              padding={{
                base: 0,
                lg: `0 0 0 0`,
              }}
            >
              € 4.500,00 lordi
            </Text>
          </Flex>
          <Image
            src="/assets/line.svg"
            alt="Hand"
            height={"200px"}
            marginTop={"20px"}
            display={{ sm: "none", lg: "block" }}
          />
          <Flex
            minWidth={{ base: "33%", lg: "33%" }}
            direction={"column"}
            textAlign={"center"}
          >
            <Text
              fontWeight={"semibold"}
              fontSize={{
                base: theme.fontSizes["3xl"],
                sm: "130px",
                md: "200px",
              }}
              lineHeight={"200px"}
              padding={{
                base: 0,
                lg: `0 0 0 0`,
              }}
              color={theme.colors.brand.accent}
            >
              2
            </Text>
            <Image
              src="/assets/star1.svg"
              alt="Hand"
              position={{ base: "absolute" }}
              display={{ sm: "block", lg: "none" }}
              left={{ md: "15%", sm: "0" }}
              marginLeft={"25%"}
            />
            <Text
              fontSize={{
                base: theme.fontSizes["sm"],
                lg: theme.fontSizes["sm"],
                xl: theme.fontSizes["md"],
              }}
              padding={{
                base: 0,
                lg: `0 0 0 0`,
              }}
            >
              € 2.500,00 lordi
            </Text>
          </Flex>
          <Image
            src="/assets/line.svg"
            alt="Hand"
            height={"200px"}
            marginTop={"20px"}
            display={{ sm: "none", lg: "block" }}
          />
          <Flex
            minWidth={{ base: "33%", lg: "33%" }}
            direction={"column"}
            textAlign={"center"}
          >
            <Text
              fontWeight={"semibold"}
              fontSize={{
                base: theme.fontSizes["3xl"],
                sm: "130px",
                md: "200px",
              }}
              lineHeight={"200px"}
              padding={{
                base: 0,
                lg: `0 0 0 0`,
              }}
              color={theme.colors.brand.accent}
            >
              3
            </Text>
            <Image
              src="/assets/star2.svg"
              alt="Hand"
              position={{ base: "absolute" }}
              display={{ sm: "block", lg: "none" }}
              right={"20%"}
            />
            <Text
              fontSize={{
                base: theme.fontSizes["sm"],
                lg: theme.fontSizes["sm"],
                xl: theme.fontSizes["md"],
              }}
              padding={{
                base: 0,
                lg: `0 0 0 0`,
              }}
            >
              € 1.500,00 lordi
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Compensi;
