import { Flex, useTheme } from "@chakra-ui/react";
import React, { useRef } from "react";
import useScrollSnap from "../../helpers/react-use-scroll-snap";

// import CookieConsent from "./CookieConsent";
import Footer from "./Footer";
import Header from "./Header";
import MidMenu from "./MidMenu";
import Chi from "./Sections/Chi";
import Cliccatori from "./Sections/Cliccatori";
import Comefunziona from "./Sections/Comefunziona";
import Comeguadagni from "./Sections/Comeguadagni";
import Compensi from "./Sections/Compensi";
import Main from "./Sections/Main";
import Perche from "./Sections/Perche";
import Requisiti from "./Sections/Requisiti";
import Tongue from "./Tongue";

import useWindowDimensions from "../../hooks/use-windows-dimensions.hook";

type HomeProps = {
  children?: React.ReactNode;
};

const Home: React.FC<HomeProps> = () => {
  const theme = useTheme();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const scrollRef = useRef(null);
  const backupRef = useRef(null);
  const scrollIdx = useScrollSnap({
    ref: scrollRef,
    duration: 100,
    delay: 50,
  });
  const pageCase = 0;
  return (
    <>
      {(windowWidth || 0) > 768 && (
        <MidMenu scrollIdx={scrollIdx} windowHeight={windowHeight || 0} />
      )}
      <div ref={(windowWidth || 0) > 768 ? backupRef : scrollRef} />
      <div
        ref={(windowWidth || 0) > 768 ? scrollRef : backupRef}
        style={{ width: "100%", height: "100%" }}
      >
        <Header
          windowWidth={windowWidth || 0}
          windowHeight={windowHeight || 0}
          scrollIdx={scrollIdx}
          pageCase={pageCase}
        />
        <Tongue />
        <Flex
          background={`linear-gradient(180deg, ${theme.colors.brand.secondary} 0%, ${theme.colors.brand.primary} 100%)`}
          direction={"column"}
          height={{
            base: "auto",
            xl: `${windowHeight}px`,
          }}
          id={"main"}
        >
          <Main />
        </Flex>

        <Flex
          direction={"column"}
          height={{
            base: "auto",
            xl: `${windowHeight}px`,
          }}
          id={"cliccatori"}
        >
          <Cliccatori />
        </Flex>
        <Flex
          background={theme.colors.brand.primary}
          direction={"column"}
          height={{
            base: "auto",
            xl: `${windowHeight}px`,
          }}
          id={"requisiti"}
        >
          <Requisiti />
        </Flex>
        <Flex
          direction={"column"}
          height={{
            base: "auto",
            xl: `${windowHeight}px`,
          }}
          id={"comefunziona"}
        >
          <Comefunziona />
        </Flex>
        <Flex
          background={theme.colors.brand.primary}
          direction={"column"}
          height={{
            base: "auto",
            xl: `${windowHeight}px`,
          }}
          id={"comeguadagni"}
        >
          <Comeguadagni />
        </Flex>
        <Flex
          background={theme.colors.brand.secondary}
          direction={"column"}
          height={{
            base: "auto",
            xl: `${windowHeight}px`,
          }}
          id={"compensi"}
        >
          <Compensi />
        </Flex>
        <Flex
          background={theme.colors.brand.accent}
          direction={"column"}
          height={{
            base: "auto",
            xl: `${windowHeight}px`,
          }}
          id={"perche"}
        >
          <Perche />
        </Flex>
        <Flex
          background={theme.colors.white}
          direction={"column"}
          height={{
            base: "auto",
            xl: `${windowHeight}px`,
          }}
          id={"chi"}
        >
          <Chi />
        </Flex>
        {/*<CookieConsent />*/}
        <Footer />
      </div>
    </>
  );
};

export default Home;
