import { Button, Flex, Image, Link, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

type MidMenuProps = {
  children?: React.ReactNode;
  scrollIdx: number;
  windowHeight: number;
};

const MidMenu: React.FC<MidMenuProps> = ({ scrollIdx, windowHeight }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Flex
      width={"225px"}
      direction={"column"}
      position={scrollIdx === 0 || scrollIdx === 1 ? "fixed" : "fixed"}
      height={windowHeight}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Button
        colorScheme="purple"
        backgroundColor={theme.colors.brand.accentSecondary}
        color={theme.colors.white}
        textTransform={"uppercase"}
        fontSize={theme.fontSizes.sm}
        borderRadius={theme.radii["3xl"]}
        onClick={() => navigate("/signup")}
      >
        Diventa Cliccatore
      </Button>
      <Flex
        color={
          scrollIdx === 0 ||
          scrollIdx === 1 ||
          scrollIdx === 2 ||
          scrollIdx === 4 ||
          scrollIdx === 6 ||
          scrollIdx === 7 ||
          scrollIdx === 8
            ? theme.colors.white
            : theme.colors.black
        }
        transition={"color 1000ms linear"}
        width={"100%"}
        padding={`${theme.space[8]} 0`}
        direction={"column"}
      >
        <Text
          textAlign={"left"}
          padding={`0 ${theme.space[6]}`}
          borderLeft={`8px solid ${
            scrollIdx === 0 || scrollIdx === 1 || scrollIdx === 2
              ? theme.colors.brand.accentSecondary
              : "transparent"
          }`}
          cursor={"pointer"}
          // onClick={() => { scrollIdx=4; window.scrollTo({top:windowHeight*0, behavior: 'smooth'})}}
        >
          <Link href="#main">Click Day 2022</Link>
        </Text>
        <Text
          textAlign={"left"}
          padding={`0 ${theme.space[6]}`}
          borderLeft={`8px solid ${
            scrollIdx === 3 ? theme.colors.brand.accentSecondary : "transparent"
          }`}
          cursor={"pointer"}
          onClick={() => {
            scrollIdx = 3;
            window.scrollTo({ top: windowHeight * 1, behavior: "smooth" });
          }}
        >
          <Link href="#cliccatori">Chi sono i cliccatori</Link>
        </Text>
        <Text
          textAlign={"left"}
          padding={`0 ${theme.space[6]}`}
          borderLeft={`8px solid ${
            scrollIdx === 4 ? theme.colors.brand.accentSecondary : "transparent"
          }`}
          cursor={"pointer"}
          // onClick=window.scroll()
          // onClick={() => navigate("/")}
        >
          <Link href="#requisiti">Requisiti</Link>
        </Text>
        <Text
          textAlign={"left"}
          padding={`0 ${theme.space[6]}`}
          borderLeft={`8px solid ${
            scrollIdx === 5 ? theme.colors.brand.accentSecondary : "transparent"
          }`}
          cursor={"pointer"}
        >
          <Link href="#comefunziona">Come funziona</Link>
        </Text>
        <Text
          textAlign={"left"}
          padding={`0 ${theme.space[6]}`}
          borderLeft={`8px solid ${
            scrollIdx === 6 ? theme.colors.brand.accentSecondary : "transparent"
          }`}
          cursor={"pointer"}
        >
          <Link href="#comeguadagni">Come guadagni</Link>
        </Text>
        <Text
          textAlign={"left"}
          padding={`0 ${theme.space[6]}`}
          borderLeft={`8px solid ${
            scrollIdx === 7 ? theme.colors.brand.accentSecondary : "transparent"
          }`}
          cursor={"pointer"}
        >
          <Link href="#compensi">Compensi</Link>
        </Text>
        <Text
          textAlign={"left"}
          padding={`0 ${theme.space[6]}`}
          borderLeft={`8px solid ${
            scrollIdx === 8 ? theme.colors.brand.accentSecondary : "transparent"
          }`}
          cursor={"pointer"}
        >
          <Link href="#perche">Perchè Click Day</Link>
        </Text>
        <Text
          textAlign={"left"}
          padding={`0 ${theme.space[6]}`}
          borderLeft={`8px solid ${
            scrollIdx === 9 ? theme.colors.brand.accentSecondary : "transparent"
          }`}
          cursor={"pointer"}
        >
          <Link href="#chi">Chi siamo</Link>
        </Text>
        <Flex padding={`${theme.space[4]} 0 0 ${theme.space[8]}`}>
          <Image
            src={`/assets/${
              scrollIdx === 0 ||
              scrollIdx === 1 ||
              scrollIdx === 2 ||
              scrollIdx === 4 ||
              scrollIdx === 6 ||
              scrollIdx === 7 ||
              scrollIdx === 8
                ? "logo_ats"
                : "logo_ats_alt"
            }.svg`}
            alt="Logo ATS"
            transition={"src 10000ms linear"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MidMenu;
