import React, { useEffect, useState, useRef } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { ApiError } from "../../helpers/api.helper";
import cacheInstance from "../../helpers/cache/cache.helper";
import getLogger from "../../helpers/logger.helper";

import { checkUser } from "./Auth.helpers";
import { AuthContext } from "./Auth.context";
import type { User } from "./Auth.context";

const logger = getLogger("Auth");

type AuthenticatorProps = {
  children?: React.ReactNode;
};

const Authenticator: React.FC<AuthenticatorProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isFirstLoad = useRef(true);
  const [user, setUser] = useState<User>();

  const isAuth = cacheInstance.getItem("isAuth");

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const { user: returnedUser, needsVerification } = await checkUser();

        if (returnedUser) {
          if (!user) setUser(returnedUser);
          if (needsVerification) {
            navigate("/verify-email", {
              state: { from: location.pathname },
              replace: true,
            });
            return;
          }
          return;
        }
      } catch (err) {
        let navigateWhere = "/logout";
        if (err instanceof ApiError) {
          if (err.code === 400) {
            navigateWhere = "/account-disabled";
          }
        }
        navigate(navigateWhere, {
          state: { from: location.pathname },
          replace: true,
        });
      }
    };

    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      if (!user) verifyToken();
    } else {
      logger.debug(`should check token validity, path ${location.pathname}`);
      verifyToken();
    }
  }, [location.pathname, navigate, user, isAuth]);

  return isAuth ? (
    <AuthContext.Provider
      value={{ user: user, scopes: cacheInstance.getItem("scopes") }}
    >
      <Outlet />
    </AuthContext.Provider>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default Authenticator;
