export interface Logger {
  debug(msg: string): void;
  info(msg: string): void;
  warn(msg: string): void;
  error(msg: string): void;
}

type LogItem = [string | object];

const LOG_LEVELS: {
  [key: string]: number;
} = {
  VERBOSE: 1,
  DEBUG: 2,
  INFO: 3,
  WARN: 4,
  ERROR: 5,
};

export enum LOG_TYPE {
  DEBUG = "DEBUG",
  ERROR = "ERROR",
  INFO = "INFO",
  WARN = "WARN",
  VERBOSE = "VERBOSE",
}

/**
 * Write logs
 * @class Logger
 */
export class ConsoleLogger implements Logger {
  name: string;
  level: LOG_TYPE | string;

  /**
   * @constructor
   * @param {string} name - Name of the logger
   */
  constructor(name: string, level: LOG_TYPE | string = LOG_TYPE.WARN) {
    this.name = name;
    this.level = level;
  }

  static LOG_LEVEL = null;

  _padding(n: number) {
    return n < 10 ? "0" + n : "" + n;
  }

  _ts() {
    const dt = new Date();
    return (
      [this._padding(dt.getMinutes()), this._padding(dt.getSeconds())].join(
        ":"
      ) +
      "." +
      dt.getMilliseconds()
    );
  }

  /**
   * Write log
   * @method
   * @memeberof Logger
   * @param {LOG_TYPE|string} type - log type, default INFO
   * @param {LogItem} msg - Logging message or object
   */
  _log(type: LOG_TYPE | string, ...msg: LogItem) {
    let logger_level_name = this.level;
    if (ConsoleLogger.LOG_LEVEL) {
      logger_level_name = ConsoleLogger.LOG_LEVEL;
    }
    const logger_level = LOG_LEVELS[logger_level_name];
    const type_level = LOG_LEVELS[type];
    if (!(type_level >= logger_level)) {
      // Do nothing if type is not greater than or equal to logger level (handle undefined)
      return;
    }

    let log = console.log.bind(console);
    if (type === LOG_TYPE.ERROR && console.error) {
      log = console.error.bind(console);
    }
    if (type === LOG_TYPE.WARN && console.warn) {
      log = console.warn.bind(console);
    }

    const prefix = `[${type}] ${this._ts()} ${this.name}`;
    let message = "";

    if (msg.length === 1 && typeof msg[0] === "string") {
      message = `${prefix} - ${msg[0]}`;
      log(message);
    } else if (msg.length === 1) {
      message = `${prefix} ${msg[0]}`;
      log(prefix, msg[0]);
    } else if (typeof msg[0] === "string") {
      let objectSlices = msg.slice(1);
      let object;
      if (objectSlices.length === 1) {
        object = objectSlices[0];
      }
      message = `${prefix} - ${msg[0]} ${object}`;
      log(`${prefix} - ${msg[0]}`, object);
    } else {
      message = `${prefix} ${msg}`;
      log(prefix, msg);
    }
  }

  /**
   * Write General log. Default to INFO
   * @method
   * @memeberof Logger
   * @param {LogItem} msg - Logging message or object
   */
  log(...msg: LogItem) {
    this._log(LOG_TYPE.INFO, ...msg);
  }

  /**
   * Write INFO log
   * @method
   * @memeberof Logger
   * @param {LogItem} msg - Logging message or object
   */
  info(...msg: LogItem) {
    this._log(LOG_TYPE.INFO, ...msg);
  }

  /**
   * Write WARN log
   * @method
   * @memeberof Logger
   * @param {LogItem} msg - Logging message or object
   */
  warn(...msg: LogItem) {
    this._log(LOG_TYPE.WARN, ...msg);
  }

  /**
   * Write ERROR log
   * @method
   * @memeberof Logger
   * @param {LogItem} msg - Logging message or object
   */
  error(...msg: LogItem) {
    this._log(LOG_TYPE.ERROR, ...msg);
  }

  /**
   * Write DEBUG log
   * @method
   * @memeberof Logger
   * @param {LogItem} msg - Logging message or object
   */
  debug(...msg: LogItem) {
    this._log(LOG_TYPE.DEBUG, ...msg);
  }

  /**
   * Write VERBOSE log
   * @method
   * @memeberof Logger
   * @param {LogItem} msg - Logging message or object
   */
  verbose(...msg: LogItem) {
    this._log(LOG_TYPE.VERBOSE, ...msg);
  }
}

const getLogger = (name: string) =>
  new ConsoleLogger(
    name,
    process.env.NODE_ENV === "production" ? "INFO" : "DEBUG"
  );

export default getLogger;
