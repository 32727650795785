import { Flex, Text, useTheme } from "@chakra-ui/react";
import React from "react";

import useWindowDimensions from "../../../hooks/use-windows-dimensions.hook";

type MainProps = {
  children?: React.ReactNode;
};

const Comefunziona: React.FC<MainProps> = () => {
  const theme = useTheme();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const isSmall = (windowWidth || 0) < 768;

  return (
    <Flex
      padding={{
        base: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        sm: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        md: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[40]}`,
        lg: `${theme.space[24]} 0 0 250px`,
        xl: `${theme.space[24]} 0 0 225px`,
      }}
      direction={{ base: "column", lg: "row" }}
      justifyContent={"center"}
    >
      <Flex
        width={{ base: "100%", lg: "50%" }}
        height={{
          base: "auto",
          lg: `calc(${windowHeight}px - ${theme.space[24]})`,
        }}
        color={theme.colors.brand.primary}
        direction={"row"}
        justifyContent={{ md: "center", lg: "right" }}
      >
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
          margin={"auto 0 auto 0"}
        >
          <Text
            fontSize={{
              base: theme.fontSizes["2xl"],
              lg: theme.fontSizes["3xl"],
              xl: theme.fontSizes["4xl"],
            }}
            lineHeight={{
              base: theme.lineHeights.shorter,
              lg: theme.lineHeights.none,
            }}
            fontWeight={theme.fontWeights.bold}
            padding={{
              base: `0 0 ${theme.space[6]} 0`,
              lg: `${theme.space[10]} 0 ${theme.space[6]} 0`,
              xl: `${theme.space[12]} 0 ${theme.space[8]} 0`,
            }}
          >
            Come funziona
            <br hidden={isSmall} /> il click?
          </Text>
          <Text
            color={theme.colors.brand.black}
            fontSize={{
              base: theme.fontSizes["lg"],
              lg: theme.fontSizes["md"],
              xl: theme.fontSizes["xl"],
            }}
            padding={{
              base: 0,
              lg: `0 0 0 0`,
              xl: `0 0 0 0`,
            }}
          >
            Il giorno del Click Day per il Bando ISI
            <br hidden={isSmall} /> INAIL, se sarai stato selezionato come
            nostro
            <br hidden={isSmall} /> Cliccatore Ufficiale dovrai procedere con
            l'invio della
            <br hidden={isSmall} /> domanda da un sito INAIL dedicato con le
            <br hidden={isSmall} /> modalità che ti spiegheremo tramite un
            <br hidden={isSmall} /> video tutorial di pochi minuti che ti
            <br hidden={isSmall} /> mostrerà nel dettaglio l'intera procedura
            <br hidden={isSmall} /> richiesta dall'Ente INAIL.
            <br />
            <br />
            Entro il 23 settembre 2022 , l'INAIL comunicherà
            <br hidden={isSmall} />
            la data effettiva del giorno e dell'orario
            <br hidden={isSmall} />
            del Click Day che si attesterà
            <br hidden={isSmall} />
            probabilmente fra l'ultima settimana di
            <br hidden={isSmall} />
            settembre 2022 e le prime due settimane di ottobre 2022.
            <br hidden={isSmall} />
            In ogni caso se sei tra i Cliccatori Ufficiali
            <br hidden={isSmall} />
            sarai costantemente aggiornato!
          </Text>
        </Flex>
      </Flex>
      <Flex
        width={{ base: "100%", lg: "50%" }}
        height={{
          base: "auto",
          lg: `calc(${windowHeight}px - ${theme.space[24]})`,
        }}
        color={theme.colors.brand.primary}
        direction={"row"}
        justifyContent={{ md: "center", lg: "left" }}
        marginTop={{ sm: "25px" }}
      >
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
          margin={"auto 0 auto 0"}
        >
          <Text
            color={theme.colors.black}
            fontSize={{
              base: theme.fontSizes["xl"],
              lg: theme.fontSizes["2xl"],
              xl: theme.fontSizes["3xl"],
            }}
            lineHeight={{
              base: theme.lineHeights.shorter,
              lg: theme.lineHeights.none,
            }}
            fontWeight={theme.fontWeights.bold}
            padding={{
              sm: `0 0 ${theme.space[6]} 0`,
              lg: `${theme.space[10]} 0 ${theme.space[6]} 0`,
              xl: `${theme.space[12]} 0 ${theme.space[8]} 0`,
            }}
            marginTop={{
              sm: "25px",
              lg: "0",
            }}
          >
            È più facile
            <br hidden={isSmall} /> di ciò che pensi!
          </Text>
          <Text
            color={theme.colors.brand.black}
            fontSize={{
              base: theme.fontSizes["lg"],
              lg: theme.fontSizes["md"],
              xl: theme.fontSizes["xl"],
            }}
            padding={{
              base: 0,
              lg: `0 0 0 0`,
            }}
          >
            Ti invieremo via email le semplici
            <br hidden={isSmall} /> istruzioni e un video tutorial che ti
            <br hidden={isSmall} /> illustreranno passo dopo passo cosa
            <br hidden={isSmall} /> dovrai fare precisamente quel giorno e
            <br hidden={isSmall} /> saremo comunque a disposizione, anche
            <br hidden={isSmall} /> telefonicamente al numero 0522 701079,
            <br hidden={isSmall} /> per qualunque dubbio o informazione
            <br hidden={isSmall} /> aggiuntiva.
            <br />
            <br />
            Sarà richiesta la partecipazione dei
            <br hidden={isSmall} /> cliccatori, davanti al proprio pc, mezz'ora
            <br hidden={isSmall} /> prima dell'orario comunicato dall'INAIL.
            <br />
            <br />
            Ogni cliccatore effettuerà il click
            <br hidden={isSmall} /> comodamente da casa propria.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Comefunziona;
