import { createContext } from "react";

type User = {
  id: string;
  first_name: string;
  last_name: string;
  phone: string;
  job: string;
  email: string;
  document: string;
  birth_date: string;
  is_active: boolean;
  is_email_verified: boolean;
  code: string;
  referrer_id?: string;
  created_at: string;
  is_test_2_allowed: 1 | 0;
  is_clickday_allowed: boolean;
  delta_1: number | null;
  delta_2: number | null;
  result_1: boolean | null;
  result_2: boolean | null;
};

type Scope =
  | "me"
  | "ref_code:read:all"
  | "ref_users:read"
  | "ref_users:read:all"
  | "ref_users:write:all"
  | "test_results:read"
  | "test_results:read:all"
  | "message:write:all"
  | "message:write:referred"
  | "message:read:referred"
  | "test_answers:write";

const AuthContext = createContext({} as { user?: User; scopes?: Scope[] });

export { AuthContext };

export type { User, Scope };
