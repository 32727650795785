import React from "react";
import { Link } from "react-router-dom";
import {
  Flex,
  Image,
  Heading,
  VStack,
  Link as UILink,
  Icon,
  useTheme,
} from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";

type NotFoundProps = {
  children?: React.ReactNode;
};

const NotFound: React.FC<NotFoundProps> = () => {
  const theme = useTheme();

  return (
    <>
      <Flex
        as="header"
        align="center"
        width={"100vw"}
        height={theme.space[20]}
        backgroundColor={theme.colors.brand.primary}
        color={theme.colors.white}
        direction={"column"}
      >
        <Flex
          width={"100%"}
          height={theme.space[20]}
          align="center"
          justifyContent={"center"}
          padding={theme.space[4]}
        >
          <Image src="/assets/logo.svg" alt="Logo" />
        </Flex>
      </Flex>
      <Flex
        justifyContent={"center"}
        height={`calc(100vh - ${theme.space[20]})`}
        alignItems={"center"}
      >
        <VStack>
          <Heading as="h1" size="2xl">
            404
          </Heading>
          <Heading as="h2" size="lg">
            Pagina Non trovata
          </Heading>
          <UILink as={Link} to="/">
            <Icon as={FiArrowLeft} mr={theme.space[2]} />
            Torna indietro
          </UILink>
        </VStack>
      </Flex>
    </>
  );
};

export default NotFound;
