import { Flex, Link as UiLink, useTheme } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

type ItemProps = {
  to: string;
  label: string;
};

const Item: React.FC<ItemProps> = ({ to, label }) => {
  const theme = useTheme();

  return (
    <Flex align="center" justify="center">
      <UiLink
        as={Link}
        to={to}
        color="inherit"
        fontWeight={theme.fontWeights.normal}
        fontSize={{ md: theme.fontSizes["sm"], xl: theme.fontSizes["lg"] }}
      >
        {label}
      </UiLink>
    </Flex>
  );
};

export default Item;

export type { ItemProps };
