import { Box, Flex, Image, Text, useTheme } from "@chakra-ui/react";
import React from "react";

import useWindowDimensions from "../../../hooks/use-windows-dimensions.hook";

type MainProps = {
  children?: React.ReactNode;
};

const Cliccatori: React.FC<MainProps> = () => {
  const theme = useTheme();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const isSmall = (windowWidth || 0) < 768;

  return (
    <Flex
      padding={{
        base: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        sm: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        md: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[40]}`,
        lg: `${theme.space[24]} 0 0 250px`,
        xl: `${theme.space[24]} 0 0 225px`,
      }}
      direction={{ base: "column", lg: "row" }}
      justifyContent={"center"}
    >
      <Flex
        width={{ base: "100%", lg: "50%" }}
        height={{
          base: "auto",
          lg: `calc(${windowHeight}px - ${theme.space[24]})`,
        }}
        color={theme.colors.brand.primary}
        direction={"row"}
        justifyContent={{ md: "center", lg: "right" }}
      >
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
          margin={"auto 0 auto 0"}
        >
          <Text
            fontSize={{
              base: theme.fontSizes["2xl"],
              lg: theme.fontSizes["3xl"],
              xl: theme.fontSizes["4xl"],
            }}
            lineHeight={{
              base: theme.lineHeights.shorter,
              lg: theme.lineHeights.none,
            }}
            fontWeight={theme.fontWeights.bold}
            padding={{
              base: `0 0 ${theme.space[6]} 0`,
              lg: `${theme.space[10]} 0 ${theme.space[6]} 0`,
              xl: `${theme.space[12]} 0 ${theme.space[8]} 0`,
            }}
          >
            Chi sono
            <br hidden={isSmall} /> i cliccatori?
          </Text>
          <Text
            color={theme.colors.brand.black}
            fontSize={{
              base: theme.fontSizes["lg"],
              lg: theme.fontSizes["md"],
              xl: theme.fontSizes["xl"],
            }}
            padding={{
              base: 0,
              lg: `0 0 0 0`,
              xl: `0 0 0 0`,
            }}
          >
            I cliccatori sono tutte quelle persone che
            <br hidden={isSmall} /> hanno tempo e voglia di partecipare al
            <br hidden={isSmall} /> progetto Click Day. Uomini e donne, che
            <br hidden={isSmall} /> vogliono e possono dedicare mezz'ora
            <br hidden={isSmall} /> del proprio tempo il giorno del Click Day
            <br hidden={isSmall} /> per fare il click.
          </Text>
          <Box
            textAlign={{ sm: "right", lg: "center" }}
            marginTop={{ sm: "25px", lg: "0" }}
          >
            <Image
              src="/assets/mouse.svg"
              alt="Hand"
              align={"right"}
              display={"inline"}
              width="200px"
              marginRight={{ sm: "0", lg: "-30px" }}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex
        width={{ base: "100%", lg: "50%" }}
        height={{
          base: "auto",
          lg: `calc(${windowHeight}px - ${theme.space[24]})`,
        }}
        color={theme.colors.brand.primary}
        direction={"row"}
        justifyContent={{ md: "center", lg: "left" }}
      >
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
          margin={"auto 0 auto 0"}
        >
          <Text
            fontSize={{
              base: theme.fontSizes["2xl"],
              lg: theme.fontSizes["3xl"],
              xl: theme.fontSizes["4xl"],
            }}
            lineHeight={{
              base: theme.lineHeights.shorter,
              lg: theme.lineHeights.none,
            }}
            fontWeight={theme.fontWeights.bold}
            padding={{
              base: `0 0 ${theme.space[6]} 0`,
              lg: `${theme.space[10]} 0 ${theme.space[6]} 0`,
              xl: `${theme.space[12]} 0 ${theme.space[8]} 0`,
            }}
          >
            Come si diventa
            <br hidden={isSmall} /> cliccatori?
          </Text>
          <Text
            color={theme.colors.brand.black}
            fontSize={{
              base: theme.fontSizes["lg"],
              lg: theme.fontSizes["md"],
              xl: theme.fontSizes["xl"],
            }}
            padding={{
              base: 0,
              lg: `0 0 0 0`,
            }}
          >
            Semplice! Prima di tutto devi iscriverti al
            <br hidden={isSmall} /> nostro portale.
            <br hidden={isSmall} /> Poi dovrai effettuare 2 Test di velocità e
            <br hidden={isSmall} /> precisione con lo scopo di valutare le tue
            <br hidden={isSmall} /> capacità nell'utilizzo degli
            <br hidden={isSmall} /> strumenti informatici necessari poi
            <br hidden={isSmall} /> all'invio del progetto all'INAIL il giorno
            del
            <br hidden={isSmall} /> click day: se dopo il Test 1 risulterai tra
            i
            <br hidden={isSmall} /> Cliccatori più veloci e precisi sarai
            <br hidden={isSmall} /> ammesso al Test 2. Se anche dopo aver
            <br hidden={isSmall} /> effettuato il Test 2 risulterai tra i più
            veloci
            <br hidden={isSmall} /> e precisi, diventerai Cliccatore Ufficiale
            <br hidden={isSmall} /> Click day 2022!
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Cliccatori;
