import React, { useEffect, createContext } from "react";
import { Flex, useColorModeValue, useToast } from "@chakra-ui/react";

import Hub from "../../helpers/hub.helper";
import useWindowDimensions from "../../hooks/use-windows-dimensions.hook";

const WindowContext = createContext({});

type BodyProps = {
  justifyContent?: string;
  alignItems?: string;
  style?: React.CSSProperties;
};

const Body: React.FC<BodyProps> = ({
  justifyContent = "auto",
  alignItems = "auto",
  style,
  children,
}) => {
  const toast = useToast();
  const { height: windowHeight } = useWindowDimensions();

  const handleNotifications = (e: CustomEvent) => {
    const { detail: payload } = e;

    toast({
      title: payload?.title || null,
      description: payload?.description || null,
      status: payload?.status || "success",
      duration: payload?.duration || 5000,
      isClosable: payload?.isCloseable || true,
    });
  };

  useEffect(() => {
    Hub.listen("notifications", handleNotifications);

    // Clean up subscriptions when the component unmounts
    return () => {
      Hub.remove("notifications", handleNotifications);
    };
  });

  return (
    <WindowContext.Provider value={{ windowHeight }}>
      <Flex
        width="100%"
        minHeight={`${windowHeight}px`}
        justifyContent={justifyContent}
        alignItems={alignItems}
        style={style}
        direction="column"
        gap="0"
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        {children}
      </Flex>
    </WindowContext.Provider>
  );
};

export { WindowContext };

export default Body;
