import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const colors = {
  brand: {
    primary: "#FF7B1C",
    secondary: "#F29400",
    accent: "#6A00F2",
    accentSecondary: "#6A00F2",
    black: "#000000",
  },
};

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1380px",
  "2xl": "1536px",
};

const fonts = {
  body: '"Poppins", sans-serif',
};

const theme = extendTheme({ colors, breakpoints, fonts });

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
