import { AxiosRequestConfig } from "axios";

import API, { handleError } from "../../helpers/api.helper";
import cacheInstance from "../../helpers/cache/cache.helper";
import type { User } from "./Auth.context";

const checkUser = async () => {
  try {
    const res = await API.get("/users/me");
    const { data, status } = res as {
      data: User;
      status: number;
    };

    return {
      user: data,
      needsVerification: status === 202,
    };
  } catch (err) {
    throw handleError(err, [400]);
  }
};

const signIn = async (username: string, password: string) => {
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("username", username);
    bodyFormData.append("password", password);
    const res = await API.post("/signin", {
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const { data, status } = res as {
      data: { scopes: string[]; expires_in: number };
      status: number;
    };

    // Keep the isAuth & scopes in the cache for the amount of seconds returned by auth flow
    const expires = new Date(
      new Date().getTime() + data.expires_in * 1000
    ).getTime();
    cacheInstance.setItem("isAuth", true, {
      expires,
      priority: 5,
    });

    cacheInstance.setItem("scopes", data.scopes, {
      expires,
      priority: 5,
    });

    return {
      needsVerification: status === 202,
    };
  } catch (err) {
    throw handleError(err, [401]);
  }
};

const getInputsFromForm = (form: HTMLFormElement) => {
  const bodyFormData = new FormData();
  Object.values(form).forEach((el) => {
    if (el.tagName === "INPUT" && el.name !== undefined && !el.disabled) {
      bodyFormData.append(el.name, el.value.trim());
    }
  });

  return bodyFormData;
};

const signUp = async (form: HTMLFormElement) => {
  const req: AxiosRequestConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  req.data = getInputsFromForm(form);
  // If a referral code is present, add it to the request as params
  if (form?.referral_code.value) {
    req.params = {
      referral_code: form?.referral_code.value,
    };
  }

  try {
    return await API.post("/signup", req);
  } catch (err) {
    throw handleError(err, [422]);
  }
};

const forgotPass = async (email: string) => {
  const req: AxiosRequestConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  req.data = new FormData();
  req.data.append("email", email);

  try {
    return await API.post("/forgot", req);
  } catch (err) {
    throw handleError(err, [400, 403, 404]);
  }
};

const resetPass = async (form: HTMLFormElement, code: string) => {
  const req: AxiosRequestConfig = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  req.data = getInputsFromForm(form);

  try {
    return await API.post(`/forgot/${code}`, req);
  } catch (err) {
    throw handleError(err, [400, 401, 403, 404, 410]);
  }
};

const verifyEmail = async (code: string) => {
  try {
    return await API.post(`/verify-email/${code}`);
  } catch (err) {
    throw handleError(err, [404, 410]);
  }
};

const logout = async () => {
  cacheInstance.clear();
  document.cookie =
    "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
};

export {
  checkUser,
  signIn,
  signUp,
  forgotPass,
  resetPass,
  verifyEmail,
  logout,
};
