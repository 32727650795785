import { Button, Flex, Image, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import useWindowDimensions from "../../../hooks/use-windows-dimensions.hook";

type MainProps = {
  children?: React.ReactNode;
};

const Main: React.FC<MainProps> = (ref) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const isSmall = (windowWidth || 0) < 768;

  return (
    <Flex
      padding={{
        base: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        sm: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        md: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[40]}`,
        lg: `${theme.space[24]} 0 0 250px`,
        xl: `${theme.space[24]} 0 0 225px`,
      }}
      direction={{ base: "column", lg: "row" }}
      justifyContent={"center"}
    >
      <Flex
        width={{ base: "100%", lg: "55%", xl: "50%" }}
        height={{
          base: "auto",
          lg: `calc(${windowHeight}px - ${theme.space[24]})`,
        }}
        color={theme.colors.white}
        direction={"row"}
        justifyContent={{ md: "center", lg: "right" }}
      >
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
          margin={"auto 0 auto 0"}
        >
          <Text
            fontSize={{
              base: theme.fontSizes["3xl"],
              lg: theme.fontSizes["4xl"],
              xl: theme.fontSizes["5xl"],
            }}
            lineHeight={{
              base: theme.lineHeights.shorter,
              lg: theme.lineHeights.none,
            }}
            fontWeight={theme.fontWeights.bold}
            padding={{
              base: `0 0 ${theme.space[6]} 0`,
              lg: `${theme.space[12]} 0 ${theme.space[8]} 0`,
            }}
          >
            Anche quest'anno
            <br hidden={isSmall} /> Click Day seleziona
            <br hidden={isSmall} /> abili cliccatori!
          </Text>
          <Text
            fontSize={{
              base: theme.fontSizes["lg"],
              lg: theme.fontSizes["lg"],
              xl: theme.fontSizes["2xl"],
            }}
            padding={{
              base: 0,
              lg: `0 0 0 0`,
            }}
          >
            Giunto alla sua XII Edizione,
            <br hidden={isSmall} /> Click Day è il progetto organizzato
            <br hidden={isSmall} /> da ATS - Consulenti Associati,
            <br hidden={isSmall} /> che raccoglie cliccatori da tutta Italia per
            <br hidden={isSmall} /> inoltrare all'INAIL le domande di tutte
            quelle
            <br hidden={isSmall} /> aziende che vogliono ottenere contributi a
            <br hidden={isSmall} /> fondo perduto volti a migliorare la
            <br hidden={isSmall} /> sicurezza e il livello di salute sui propri
            <br hidden={isSmall} /> luoghi di lavoro.
          </Text>
        </Flex>
      </Flex>

      <Flex
        width={{ base: "100%", lg: "45%", xl: "50%" }}
        height={{
          base: "auto",
          lg: `calc(${windowHeight}px - ${theme.space[24]})`,
        }}
        color={theme.colors.white}
        direction={"row"}
        justifyContent={"left"}
      >
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px" }}
          margin={"auto 0 auto 0"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src="/assets/hand.svg"
            alt="Hand"
            position={{ base: "static", lg: "absolute" }}
            margin={{ base: `${theme.space[24]} 0`, lg: 0 }}
          />
          <Flex
            direction={"column"}
            width={{
              md: "auto",
              lg: "200px",
            }}
            margin={{
              base: 0,
              lg: `${theme.space[56]} 0 0 ${theme.space[20]}`,
            }}
          >
            <Text
              fontSize={{
                base: theme.fontSizes["md"],
                lg: theme.fontSizes["xl"],
              }}
              fontWeight={theme.fontWeights.bold}
              textAlign={"center"}
              padding={{
                base: `0 0 ${theme.space[4]} 0`,
              }}
            >
              Vuoi diventare anche tu <br /> un cliccatore?
            </Text>
            <Button
              colorScheme="white"
              backgroundColor={theme.colors.white}
              color={theme.colors.black}
              textTransform={"uppercase"}
              fontSize={theme.fontSizes.sm}
              borderRadius={theme.radii["3xl"]}
              onClick={() => navigate("/signup")}
              padding={{
                sm: `0 75px 0 75px`,
                lg: `0 ${theme.space[6]} 0 ${theme.space[6]}`,
              }}
            >
              Iscriviti subito
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Main;
