class Hub {
  public listen = (event: string, callback: Function) => {
    window.addEventListener(event, callback as EventListener);
  };

  public remove = (event: string, callback: Function) => {
    window.removeEventListener(event, callback as EventListener);
  };

  public dispatch = (event: string, payload: any) => {
    window.dispatchEvent(new CustomEvent(event, { detail: payload }));
  };
}

export default new Hub();
