import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NotFound from "./NotFound";
import Authenticator from "../Auth";
import Body from "./Body";
import Home from "../Home";

import getLogger from "../../helpers/logger.helper";

const Bando = lazy(() => import("../Home/Bando"));
const ChiSiamo = lazy(() => import("../Home/ChiSiamo"));
const Faq = lazy(() => import("../Home/Faq"));
const Esperienza = lazy(() => import("../Home/Esperienza"));
const Dashboard = lazy(() => import("../Dashboard"));
const Profile = lazy(() => import("../Dashboard/Profile"));
const SignIn = lazy(() => import("../Auth/SignIn"));
const ForgotPass = lazy(() => import("../Auth/ForgotPass"));
const ResetPass = lazy(() => import("../Auth/ResetPass"));
const AccountDisabled = lazy(() => import("../Auth/AccountDisabled"));
const SignUp = lazy(() => import("../Auth/SignUp"));
const VerifyEmail = lazy(() => import("../Auth/VerifyEmail"));
const Logout = lazy(() => import("../Auth/Logout"));
const Users = lazy(() => import("../Dashboard/Users"));
const Messages = lazy(() => import("../Dashboard/Messages"));
const Results = lazy(() => import("../Dashboard/Results"));
const Tests = lazy(() => import("../Dashboard/Tests"));
const ProfileEdit = lazy(() => import("../Dashboard/Profile/ProfileEdit"));
const ProfileView = lazy(() => import("../Dashboard/Profile/ProfileView"));

const logger = getLogger("App");

type AppProps = {
  children?: React.ReactNode;
};

const App: React.FC<AppProps> = () => {
  useEffect(() => {
    logger.info("App initialized");
  }, []);

  return (
    <BrowserRouter>
      <Body>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bando" element={<Bando />} />
            <Route path="/chisiamo" element={<ChiSiamo />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/esperienza" element={<Esperienza />} />
            <Route path="/dashboard" element={<Authenticator />}>
              <Route path="/dashboard" element={<Dashboard />}>
                <Route path="profile" element={<Profile />} />
                <Route path="profile/view/:userId" element={<ProfileView />} />
                <Route path="profile/edit/:userId" element={<ProfileEdit />} />
                <Route path="messages" element={<Messages />} />
                <Route path="users" element={<Users />} />
                <Route path="results" element={<Results />} />
                <Route path="tests/:testNo" element={<Tests />} />
              </Route>
            </Route>
            <Route path="/login" element={<SignIn />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/forgot" element={<ForgotPass />} />
            <Route path="/reset-pass" element={<ResetPass />} />
            <Route path="/account-disabled" element={<AccountDisabled />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Body>
    </BrowserRouter>
  );
};

export default App;
