import { Flex, Image, Link, Text, useTheme } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type FooterProps = {
  children?: React.ReactNode;
};

const Footer: React.FC<FooterProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/signup');
  };

  return (
    <>
      <Flex
        width={'100vw'}
        direction={{ base: 'column', lg: 'row' }}
        justifyContent={'center'}
        height={{ base: 'auto', lg: theme.space[96] }}
        backgroundColor={theme.colors.brand.primary}
        padding={{
          base: `0 ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
          sm: `0 ${theme.space[10]} ${theme.space[32]} 0`,
          md: `0 ${theme.space[10]} ${theme.space[32]} 0`,
          lg: `0 0 0 250px`,
          xl: `0 0 0 225px`,
        }}
      >
        <Flex
          width={{ base: '100%', lg: '50%' }}
          height={'100%'}
          direction={'row'}
          justifyContent={{ sm: 'center', lg: 'right' }}
          alignItems={{ base: 'center', lg: 'center' }}
          padding={{
            base: `${theme.space[32]} 0 ${theme.space[24]} 0`,
            lg: `0 ${theme.space[32]} 0 0`,
            xl: `0 15rem 0 0`,
          }}
        >
          <Flex direction={{ base: 'column', lg: 'column' }}>
            <Text
              width={'100%'}
              textAlign={{ base: 'center', lg: 'left' }}
              color={theme.colors.brand.accent}
              fontSize={{
                base: theme.fontSizes['2xl'],
                lg: theme.fontSizes.medium,
              }}
            >
              Non perdere tempo
            </Text>
            <Text
              width={'100%'}
              textAlign={{ base: 'center', lg: 'left' }}
              color={theme.colors.brand.accent}
              fontSize={{
                base: theme.fontSizes['2xl'],
                lg: theme.fontSizes['3xl'],
              }}
              fontWeight={theme.fontWeights.bold}
              onClick={handleClick}
              cursor={'pointer'}
            >
              Inscriviti subito
            </Text>
            <Flex
              width={'100%'}
              justifyContent={{ base: 'center', lg: 'flex-start' }}
              alignItems={'center'}
              margin={`${theme.space[12]} 0 0 0`}
              cursor={'pointer'}
            >
              <Image
                src="/assets/home_arrow.svg"
                alt="Instriviti subito"
                width={{ base: '40%', lg: 'auto' }}
                onClick={handleClick}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          width={{ base: '100%', lg: '50%' }}
          height={'100%'}
          direction={'row'}
          justifyContent={{ sm: 'center', lg: 'left' }}
          alignItems={{ base: 'center', lg: 'center' }}
          padding={{
            base: `0 0 0 0`,
            lg: `0 0 0 0`,
            xl: `0 0 0 0`,
          }}
        >
          <Flex direction={{ base: 'column', lg: 'column' }}>
            <Text
              width={'100%'}
              textAlign={{ base: 'center', lg: 'left' }}
              color={theme.colors.brand.accent}
              fontSize={theme.fontSizes.lg}
            >
              Vuoi maggiori informazioni?
            </Text>
            <Text
              width={'100%'}
              textAlign={{ base: 'center', lg: 'left' }}
              color={theme.colors.brand.accent}
              fontSize={{
                base: theme.fontSizes.lg,
                lg: theme.fontSizes['3xl'],
              }}
              fontWeight={theme.fontWeights.bold}
            >
              Scrivici!
            </Text>
            <Text
              width={'100%'}
              textAlign={{ base: 'center', lg: 'left' }}
              color={theme.colors.brand.accent}
              fontSize={theme.fontSizes.lg}
            >
              info@clickdayats.it
            </Text>
            <Text
              width={'100%'}
              textAlign={{ base: 'center', lg: 'left' }}
              color={theme.colors.brand.accent}
              fontWeight={theme.fontWeights.light}
              fontSize={theme.fontSizes['4xl']}
            >
              -
            </Text>
            <Text
              width={'100%'}
              textAlign={{ base: 'center', lg: 'left' }}
              color={theme.colors.brand.accent}
              fontSize={theme.fontSizes.lg}
            >
              oppure chiamaci al numero:
            </Text>
            <Text
              width={'100%'}
              textAlign={{ base: 'center', lg: 'left' }}
              color={theme.colors.brand.accent}
              fontSize={theme.fontSizes.lg}
            >
              0522 701079
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex width="90vw" margin="auto">
        <Text textAlign={'center'}>
          <Link href="http://www.atseco.it/" target="_blank" color={'orange'}>
            ATS-CONSULENTI-ASSOCIATI
          </Link>{' '}
          s.r.l. Sede: Via J.F. Kennedy 17, 42124 Reggio Emilia Tel. 05222701079
          / 0522300824 - Fax. 0522379946 - PEC:{' '}
          <Link href="mailto:atseco@pec.it" target="_blank" color={'orange'}>
            atseco@pec.it
          </Link>
          C.F. e P.Iva Reg. Impr.: RE 01645400357 - Cap. Soc. € 35.000,00 int.
          vers. - REA 201850 del 04/09/1995 <br />
          <Link
            href={'/assets/Privacy_Policy_CLICKDAYATS_Regolamento_UE.pdf'}
            target="_blank"
            color={'orange'}
          >
            Privacy Policy e Cookie Policy
          </Link>
          <br />
          Made in /SbATCH
        </Text>
      </Flex>
    </>
  );
};

export default Footer;
