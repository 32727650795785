import { Flex, Image, Link, Text, useTheme } from "@chakra-ui/react";
import React from "react";

const Tongue: React.FC = () => {
  const theme = useTheme();

  return (
    <Flex direction={"column"}>
      <Flex
        width={"150px"}
        direction={"row"}
        height={"auto"}
        justifyContent={"left"}
        alignItems={"center"}
        position={"fixed"}
        right={"-107px"}
        top={"225px"}
        backgroundColor={theme.colors.brand.accent}
        display={{ sm: "none", lg: "flex" }}
        _hover={{
          transform: "translateX(-107px)",
        }}
        transition={"transform 300ms ease-in;"}
      >
        <Image
          src={`/assets/phone.svg`}
          padding={"12px 8px 12px 12px"}
          cursor={"pointer"}
          alt="Phone"
        />
        <Text
          color={theme.colors.brand.primary}
          fontSize={theme.fontSizes["sm"]}
          marginLeft={"10px"}
        >
          0522/701079
        </Text>
      </Flex>
      <Flex
        width={"200px"}
        direction={"row"}
        height={"auto"}
        justifyContent={"left"}
        alignItems={"center"}
        position={"fixed"}
        right={"-157px"}
        top={"269px"}
        backgroundColor={theme.colors.brand.accent}
        display={{ sm: "none", lg: "flex" }}
        _hover={{
          transform: "translateX(-157px)",
        }}
        transition={"transform 300ms ease-in;"}
      >
        <Image
          src={`/assets/mail.svg`}
          padding={"12px 8px 12px 12px"}
          cursor={"pointer"}
          alt="Phone"
        />
        <Link href="mailto:info@clickdayats.it" isExternal>
          <Text
            color={theme.colors.brand.primary}
            fontSize={theme.fontSizes["sm"]}
            marginLeft={"10px"}
          >
            info@clickdayats.it
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
};

export default Tongue;
