import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import React from "react";

import useWindowDimensions from "../../../hooks/use-windows-dimensions.hook";

type MainProps = {
  children?: React.ReactNode;
};

const Chi: React.FC<MainProps> = () => {
  const theme = useTheme();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const isSmall = (windowWidth || 0) < 768;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      padding={{
        base: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        sm: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        md: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[40]}`,
        lg: `${theme.space[24]} 0 0 250px`,
        xl: `${theme.space[24]} 0 0 225px`,
      }}
      direction={{ base: "column", lg: "row" }}
      justifyContent={"center"}
    >
      <Flex
        width={{ base: "100%", lg: "50%" }}
        height={{
          base: "auto",
          lg: `calc(${windowHeight}px - ${theme.space[24]})`,
        }}
        color={theme.colors.brand.primary}
        direction={"row"}
        justifyContent={{ md: "center", lg: "right" }}
      >
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
          margin={"50px 0 auto 0"}
          marginTop={{ md: "auto 0 auto 0", lg: `50px 0 auto 0` }}
        >
          <Text
            fontSize={{
              base: theme.fontSizes["2xl"],
              lg: theme.fontSizes["3xl"],
              xl: theme.fontSizes["4xl"],
            }}
            lineHeight={{
              base: theme.lineHeights.shorter,
              lg: theme.lineHeights.none,
            }}
            fontWeight={theme.fontWeights.bold}
            padding={{
              base: `0 0 ${theme.space[6]} 0`,
              lg: `0 0 ${theme.space[6]} 0`,
              xl: `0 0 ${theme.space[8]} 0`,
            }}
          >
            Chi gestisce
            <br hidden={isSmall} /> il progetto?
          </Text>

          <Box
            marginTop={{ sm: "25px", lg: `${theme.space[24]}` }}
            textAlign={{ sm: "center", md: `left` }}
          >
            <Image
              src="/assets/logo_ats_alt.svg"
              alt="Hand"
              align={"right"}
              display={"inline"}
              width="165px"
              margin={{
                base: `${theme.space[24]} 0`,
                lg: 0,
                sm: `${theme.space[24]} auto`,
              }}
              marginRight={"50px"}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex
        width={{ base: "100%", lg: "50%" }}
        height={{
          base: "auto",
          lg: `calc(${windowHeight}px - ${theme.space[24]})`,
        }}
        color={theme.colors.brand.primary}
        direction={"row"}
        justifyContent={{ md: "center", lg: "left" }}
      >
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
          margin={"auto 0 auto 0"}
          display={{ sm: "none", lg: "flex" }}
        >
          <Text
            color={theme.colors.brand.black}
            fontSize={{
              base: theme.fontSizes["lg"],
              lg: theme.fontSizes["md"],
              xl: theme.fontSizes["xl"],
            }}
            padding={{
              base: 0,
              lg: `0 0 0 0`,
            }}
            marginTop={{ sm: "25px", lg: `300px` }}
          >
            ATS – CONSULENTI ASSOCIATI S.r.l si
            <br hidden={isSmall} /> occupa, per conto dei propri clienti, oltre
            <br hidden={isSmall} /> alla gestione amministrativa e tecnica
            <br hidden={isSmall} /> dei progetti, di inviare le domande
            <br hidden={isSmall} /> all'INAIL il giorno del Click Day.
          </Text>
        </Flex>
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
          margin={"auto 0 auto 0"}
          display={{ sm: "flex", lg: "none" }}
        >
          <Box
            padding={{
              base: `${theme.space[12]} 0 0 0`,
            }}
            textAlign={{ sm: "center", md: `left` }}
          >
            <Button
              colorScheme="white"
              backgroundColor={theme.colors.brand.primary}
              color={theme.colors.white}
              textTransform={"uppercase"}
              fontSize={theme.fontSizes.sm}
              borderRadius={theme.radii["3xl"]}
              padding={{
                sm: `0 50px 0 50px`,
                lg: `0 ${theme.space[6]} 0 ${theme.space[6]}`,
              }}
              margin={{
                md: "0 auto",
                lg: "0",
              }}
              onClick={onOpen}
            >
              Continua a leggere
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
              <ModalOverlay />
              <ModalContent
                borderRadius={"0"}
                padding={"25px"}
                backgroundColor={theme.colors.brand.accent}
                maxWidth={{
                  base: "90%",
                  xl: "760px",
                  lg: "640px",
                  md: "640px",
                }}
                height={{ base: "90%", xl: "auto", lg: "auto", md: "auto" }}
                verticalAlign={"center"}
              >
                <ModalCloseButton
                  color={theme.colors.white}
                  fontSize={"25px"}
                />
                <ModalBody
                  alignContent={"center"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text color={theme.colors.white}>
                    ATS – CONSULENTI ASSOCIATI S.r.l si occupa, per conto dei
                    propri clienti, oltre alla gestione amministrativa e tecnica
                    dei progetti, di inviare le domande all'INAIL il giorno del
                    Click Day.
                    <br />
                    <br />
                    Dato il numero elevato di domande da inviare, oltre ad
                    impiegare i propri i dipendenti e collaboratori, seleziona
                    ogni anno dei cliccatori su tutto il territorio nazionale,
                    per meglio fronteggiare il numero elevato di pratiche
                    presentate.
                  </Text>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Chi;
