import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import React from "react";

import useWindowDimensions from "../../../hooks/use-windows-dimensions.hook";

type MainProps = {
  children?: React.ReactNode;
};

const Perche: React.FC<MainProps> = () => {
  const theme = useTheme();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const isSmall = (windowWidth || 0) < 768;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      padding={{
        base: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        sm: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        md: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[40]}`,
        lg: `${theme.space[24]} 0 0 250px`,
        xl: `${theme.space[24]} 0 0 225px`,
      }}
      direction={{ base: "column", lg: "column" }}
    >
      <Flex direction={{ base: "column", lg: "row" }} justifyContent={"center"}>
        <Flex
          width={{ base: "100%", lg: "50%" }}
          height={{
            base: "auto",
            lg: `calc((${windowHeight}px*0.4) - ${theme.space[24]}/2)`,
          }}
          color={theme.colors.white}
          direction={"row"}
          justifyContent={{ md: "center", lg: "right" }}
        >
          <Flex
            direction={"column"}
            width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
            margin={"auto 0 auto 0"}
          >
            <Text
              fontSize={{
                base: theme.fontSizes["3xl"],
                lg: theme.fontSizes["4xl"],
                xl: theme.fontSizes["5xl"],
              }}
              lineHeight={{
                base: theme.lineHeights.shorter,
                lg: theme.lineHeights.none,
              }}
              fontWeight={theme.fontWeights.bold}
              padding={{
                base: `0 0 ${theme.space[6]} 0`,
                lg: `${theme.space[12]} 0 ${theme.space[8]} 0`,
              }}
              color={theme.colors.brand.primary}
            >
              Perchè
              <br hidden={isSmall} /> Click Day?
            </Text>
            <Text
              fontSize={{
                base: theme.fontSizes["sm"],
                lg: theme.fontSizes["lg"],
                xl: theme.fontSizes["lg"],
              }}
              padding={{
                base: 0,
                lg: `0 0 0 0`,
              }}
              color={theme.colors.brand.primary}
            >
              Il fine è quello di ridurre sui luoghi
              <br hidden={isSmall} /> di lavoro il numero e la gravità
              <br hidden={isSmall} /> degli infortuni.
            </Text>
          </Flex>
        </Flex>
        <Flex
          width={{ base: "100%", lg: "50%" }}
          height={{
            base: "auto",
            lg: `calc((${windowHeight}px*0.4) - ${theme.space[24]}/2)`,
          }}
          color={theme.colors.white}
          direction={"row"}
          justifyContent={{ md: "center", lg: "left" }}
        >
          <Flex
            direction={"column"}
            width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
            margin={"auto 0 auto 0"}
          >
            <Box textAlign={"center"}>
              <Image
                src="/assets/helmet.svg"
                alt="Hand"
                margin={{
                  base: `${theme.space[24]} 0`,
                  lg: 0,
                  sm: `${theme.space[24]} auto`,
                }}
                maxWidth={{ lg: `270px`, xl: `270px` }}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        width={{ base: "100%", lg: "100%" }}
        height={{
          base: "auto",
          lg: `calc((${windowHeight}px*0.6) - ${theme.space[24]}/2)`,
        }}
        color={theme.colors.white}
        direction={{ base: "column", lg: "row" }}
        justifyContent={"center"}
      >
        <Flex
          width={{ base: "100%", lg: "50%" }}
          height={{
            base: "auto",
            lg: `calc((${windowHeight}px*0.6) - ${theme.space[24]}/2)`,
          }}
          color={theme.colors.brand.black}
          direction={"row"}
          justifyContent={{ md: "center", lg: "right" }}
        >
          <Flex
            direction={"column"}
            width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
            margin={"auto 0 auto 0"}
            display={{ sm: "none", lg: "flex" }}
          >
            <Text
              color={theme.colors.white}
              fontSize={{
                base: theme.fontSizes["lg"],
                lg: theme.fontSizes["md"],
                xl: theme.fontSizes["xl"],
              }}
              padding={{
                base: 0,
                lg: `0 0 0 0`,
                xl: `0 0 0 0`,
              }}
            >
              L'INAIL (Istituto Nazionale Assicurazione
              <br hidden={isSmall} /> contro gli Infortuni sul Lavoro) è l'Ente
              <br hidden={isSmall} /> Pubblico italiano che si occupa di
              <br hidden={isSmall} /> tutelare, dal punto di vista assicurativo,
              le
              <br hidden={isSmall} /> vittime degli infortuni sul lavoro. L'Ente
              ha
              <br hidden={isSmall} /> infatti introdotto e realizzato diverse
              <br hidden={isSmall} /> iniziative rivolte alle imprese, con
              <br hidden={isSmall} /> particolare attenzione al mondo delle
              <br hidden={isSmall} /> medie e piccole, fornendo consulenza,
              <br hidden={isSmall} /> orientamento e formazione in materia di
              <br hidden={isSmall} /> prevenzione degli infortuni, incentivando
              <br hidden={isSmall} /> inoltre, a mezzo di contributi ed
              <br hidden={isSmall} /> agevolazioni, anche quelle PMI che
              <br hidden={isSmall} /> hanno investito ed investono in sicurezza.
            </Text>
          </Flex>
        </Flex>
        <Flex
          width={{ base: "100%", lg: "50%" }}
          height={{
            base: "auto",
            lg: `calc((${windowHeight}px*0.6) - ${theme.space[24]}/2)`,
          }}
          color={theme.colors.white}
          direction={"row"}
          justifyContent={{ md: "center", lg: "left" }}
        >
          <Flex
            direction={"column"}
            width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
            margin={"auto 0 auto 0"}
            display={{ sm: "none", lg: "flex" }}
          >
            <Text
              color={theme.colors.brand.white}
              fontSize={{
                base: theme.fontSizes["lg"],
                lg: theme.fontSizes["md"],
                xl: theme.fontSizes["xl"],
              }}
              padding={{
                base: 0,
                lg: `0 0 0 0`,
              }}
              marginTop={{ sm: "25px" }}
            >
              Anche quest'anno, l'INAIL mette a
              <br hidden={isSmall} /> disposizione delle imprese dei contributi
              <br hidden={isSmall} /> per progetti di miglioramento dei livelli
              di
              <br hidden={isSmall} /> salute e sicurezza nei luoghi di lavoro.
              <br hidden={isSmall} /> Essendo limitati i contributi a
              <br hidden={isSmall} /> disposizione, l'INAIL li concede ad un
              <br hidden={isSmall} /> numero ristretto di domande, che sono
              <br hidden={isSmall} /> appunto quelle che si posizioneranno per
              <br hidden={isSmall} /> prime in ordine cronologico sul loro
              <br hidden={isSmall} /> portale il giorno del Click Day.
            </Text>
          </Flex>
          <Flex
            direction={"column"}
            width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
            margin={"auto 0 auto 0"}
            display={{ sm: "flex", lg: "none" }}
          >
            <Box
              padding={{
                base: `${theme.space[12]} 0 0 0`,
              }}
              textAlign={{ sm: "center", md: `left` }}
            >
              <Button
                colorScheme="white"
                backgroundColor={theme.colors.brand.primary}
                color={theme.colors.brand.accent}
                textTransform={"uppercase"}
                fontSize={theme.fontSizes.sm}
                borderRadius={theme.radii["3xl"]}
                padding={{
                  sm: `0 50px 0 50px`,
                  lg: `0 ${theme.space[6]} 0 ${theme.space[6]}`,
                }}
                margin={{
                  md: "0 auto",
                  lg: "0",
                }}
                onClick={onOpen}
              >
                Continua a leggere
              </Button>
              <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent
                  borderRadius={"0"}
                  padding={"25px"}
                  backgroundColor={theme.colors.brand.accent}
                  maxWidth={{
                    base: "90%",
                    xl: "760px",
                    lg: "640px",
                    md: "640px",
                  }}
                  height={{ base: "90%", xl: "auto", lg: "auto", md: "auto" }}
                  verticalAlign={"center"}
                >
                  {/*<ModalHeader*/}
                  {/*    color={theme.colors.white}*/}
                  {/*>*/}
                  {/*    Requisiti tecnici*/}
                  {/*</ModalHeader>*/}
                  <ModalCloseButton
                    color={theme.colors.white}
                    fontSize={"25px"}
                  />
                  <ModalBody
                    alignContent={"center"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text color={theme.colors.white}>
                      L'INAIL (Istituto Nazionale Assicurazione contro gli
                      Infortuni sul Lavoro) è l'Ente Pubblico italiano che si
                      occupa di tutelare, dal punto di vista assicurativo, le
                      vittime degli infortuni sul lavoro. L'Ente ha infatti
                      introdotto e realizzato diverse iniziative rivolte alle
                      imprese, con particolare attenzione al mondo delle medie e
                      piccole, fornendo consulenza, orientamento e formazione in
                      materia di prevenzione degli infortuni, incentivando
                      inoltre, a mezzo di contributi ed agevolazioni, anche
                      quelle PMI che hanno investito ed investono in sicurezza.
                      <br />
                      <br />
                      Anche quest'anno, l'INAIL mette a disposizione delle
                      imprese dei contributi per progetti di miglioramento dei
                      livelli di salute e sicurezza nei luoghi di lavoro.
                      Essendo limitati i contributi a disposizione, l'INAIL li
                      concede ad un numero ristretto di domande, che sono
                      appunto quelle che si posizioneranno per prime in ordine
                      cronologico sul loro portale il giorno del Click Day.{" "}
                    </Text>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Perche;
