import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

import cacheInstance from "./cache/cache.helper";
import { ICache } from "./cache/types";
import Hub from "./hub.helper";
import getLogger from "./logger.helper";

const logger = getLogger("API");

const GUEST_ROUTES_REGEX =
  /^\/((\bforgot\b(\/)*([ A-Za-z0-9_-]{12})*)|(\bsignup\b)|(\bsignin\b))$/gm;

const isGuestRoute = (url: string): boolean =>
  url.search(GUEST_ROUTES_REGEX) !== -1;

const isProtectedRoute = (url: string): boolean => !isGuestRoute(url);

class NoTokenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NoTokenError";
  }
}

class ApiError extends Error {
  code: number;
  constructor(message: string, code: number) {
    super(message);
    this.name = "ApiError";
    this.code = code;
  }
}

class API {
  public client: AxiosInstance;
  private cache: ICache;

  public constructor() {
    this.client = axios.create({
      baseURL:
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_API_BASE_URL
          : "http://localhost:8000",
      timeout: process.env.NODE_ENV === "production" ? 35000 : 2000,
      withCredentials: true,
    });
    this.cache = cacheInstance;
  }

  public get = async (
    url: string,
    config?: AxiosRequestConfig
  ): Promise<any> => {
    const reqConfig = Object.assign({}, config, { method: "get" });
    return await this.request(url, reqConfig);
  };

  public post = async (
    url: string,
    config?: AxiosRequestConfig
  ): Promise<any> => {
    const reqConfig = Object.assign({}, config, { method: "post" });
    return await this.request(url, reqConfig);
  };

  public delete = async (
    url: string,
    config?: AxiosRequestConfig
  ): Promise<any> => {
    const reqConfig = Object.assign({}, config, { method: "delete" });
    return await this.request(url, reqConfig);
  };

  public patch = async (
    url: string,
    config?: AxiosRequestConfig
  ): Promise<any> => {
    const reqConfig = Object.assign({}, config, { method: "patch" });
    return await this.request(url, reqConfig);
  };

  private request = async (
    url: string,
    config: AxiosRequestConfig<any>
  ): Promise<any> => {
    const isAuth = this.cache.getItem("isAuth");

    if (isProtectedRoute(url) && !isAuth) {
      logger.error("Token invalid or not found");
      throw new NoTokenError("Token invalid or not found");
    }

    return await this.client(url, {
      ...config,
    });
  };
}

const throwError = (err: unknown, message: string = "Errore di sistema") => {
  logger.error(err as Error);

  Hub.dispatch("notifications", {
    description: message,
    status: "error",
    isCloseable: true,
  });

  throw err;
};

const throwApiError = (message: string, status: number) => {
  if ((!message.includes('Test')) && (!message.includes('test'))) {
    Hub.dispatch("notifications", {
      description: message,
      status: "error",
      isCloseable: true,
    });
  }
  logger.error(message);
  throw new ApiError(message, status);
};

const throwTokenError = (err?: NoTokenError) => {
  // Hub.dispatch("notifications", {
  //   description: "Sessione scaduta, effettua nuovamente il login",
  //   status: "error",
  //   isCloseable: true,
  // });
  if (err) throw err;
  logger.error("Token invalid or not found");
  throw new NoTokenError("Token invalid or not found");
};

const handleError = (
  err: unknown,
  apiErrorCodes: number[],
  callback?: (err: AxiosError) => void
) => {
  let message = "Errore di sistema";

  if (axios.isAxiosError(err)) {
    const { response } = err as AxiosError;
    if (
      response !== undefined &&
      apiErrorCodes.indexOf(response?.status) !== -1
    ) {
      if (callback) {
        callback(err);
      }
      throwApiError(response.data.detail || "", response.status);
    } else if (response?.status === 401) {
      throwTokenError();
    } else {
      message = "Errore di connessione";
    }
  } else if (err instanceof NoTokenError) {
    throwTokenError(err);
  }

  throwError(err, message);
};

export default new API();

export {
  NoTokenError,
  ApiError,
  throwError,
  throwApiError,
  throwTokenError,
  handleError,
};
