import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import useWindowDimensions from "../../../hooks/use-windows-dimensions.hook";

type MainProps = {
  children?: React.ReactNode;
};

const Comefunziona: React.FC<MainProps> = () => {
  const theme = useTheme();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const isSmall = (windowWidth || 0) < 768;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      padding={{
        base: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        sm: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[10]}`,
        md: `${theme.space[32]} ${theme.space[10]} ${theme.space[32]} ${theme.space[40]}`,
        lg: `${theme.space[24]} 0 0 250px`,
        xl: `${theme.space[24]} 0 0 225px`,
      }}
      direction={{ base: "column", lg: "row" }}
      justifyContent={"center"}
    >
      <Flex
        width={{ base: "100%", lg: "50%" }}
        height={{
          base: "auto",
          lg: `calc(${windowHeight}px - ${theme.space[24]})`,
        }}
        color={theme.colors.white}
        direction={"row"}
        justifyContent={{ md: "center", lg: "right" }}
      >
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
          margin={"auto 0 auto 0"}
        >
          <Text
            fontSize={{
              base: theme.fontSizes["3xl"],
              lg: theme.fontSizes["4xl"],
              xl: theme.fontSizes["5xl"],
            }}
            lineHeight={{
              base: theme.lineHeights.shorter,
              lg: theme.lineHeights.none,
            }}
            fontWeight={theme.fontWeights.bold}
            padding={{
              base: `0 0 ${theme.space[6]} 0`,
              lg: `${theme.space[12]} 0 ${theme.space[8]} 0`,
            }}
          >
            Come
            <br hidden={isSmall} /> guadagni
          </Text>
          <Flex
            direction={"column"}
            width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
            margin={"auto 0 auto 0"}
            display={{ sm: "flex", lg: "none" }}
          >
            <Box textAlign={"center"}>
              <Image
                src="/assets/euro.svg"
                alt="Hand"
                margin={{
                  base: `${theme.space[24]} 0`,
                  lg: 0,
                  sm: `${theme.space[24]} auto`,
                }}
                maxWidth={{ sm: "300px", lg: `330px`, xl: `100%` }}
              />
            </Box>
          </Flex>
          <Text
            fontSize={{
              base: theme.fontSizes["lg"],
              lg: theme.fontSizes["lg"],
              xl: theme.fontSizes["2xl"],
            }}
            padding={{
              base: 0,
              lg: `0 0 0 0`,
            }}
          >
            Se il tuo click risulterà vincente
            <br hidden={isSmall} /> 1.000 € per te!
          </Text>
          <Box
            padding={{
              base: `${theme.space[12]} 0 0 0`,
            }}
            textAlign={{ sm: "center", md: `left` }}
          >
            <Button
              colorScheme="white"
              backgroundColor={theme.colors.white}
              color={theme.colors.brand.primary}
              textTransform={"uppercase"}
              fontSize={theme.fontSizes.sm}
              borderRadius={theme.radii["3xl"]}
              padding={{
                sm: `0 50px 0 50px`,
                lg: `0 ${theme.space[6]} 0 ${theme.space[6]}`,
              }}
              margin={{
                md: "0 auto",
                lg: "0",
              }}
              onClick={onOpen}
            >
              Continua a leggere
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
              <ModalOverlay />
              <ModalContent
                borderRadius={"0"}
                padding={"25px"}
                backgroundColor={theme.colors.brand.accent}
                maxWidth={{
                  base: "90%",
                  xl: "760px",
                  lg: "640px",
                  md: "640px",
                }}
                height={{ base: "90%", xl: "auto", lg: "auto", md: "auto" }}
                verticalAlign={"center"}
              >
                <ModalCloseButton
                  color={theme.colors.white}
                  fontSize={"25px"}
                />
                <ModalBody
                  alignContent={"center"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text color={theme.colors.white}>
                    Con il tuo Click: <br />
                    <p>
                      Se sarai selezionato come Cliccatore Ufficiale,
                      guadagnerai € 100,00 lordi, pari a € 80,00 netti, che ti
                      verranno corrisposti in seguito all'invio dello screenshot
                      che prova l'avvenuto inoltro della domanda di contributo
                      sul sito dell'Ente INAIL il giorno del Click Day. Tale
                      compenso ti sarà erogato indipendentemente
                      dall'ottenimento del contributo per l'azienda a te
                      associata.
                    </p>
                    <br />
                    <p>
                      Un ulteriore compenso di € 900,00 lordi, pari ad € 720,00
                      netti, ti verrà corrisposto nel caso in cui il tuo Click
                      ottenga l'ammissibilità al contributo per l'azienda a te
                      associata; tale compenso ulteriore sarà erogato entro 30
                      giorni dalla data di pubblicazione degli elenchi
                      cronologici relativi ai progetti ammessi al contributo
                      INAIL. Tali elenchi cronologici saranno pubblicati sul
                      sito dell'INAIL presumibilmente entro 14 giorni dalla data
                      del Click Day.
                    </p>
                    <br />
                    <p>
                      Per far sì che il progetto da te inviato ottenga
                      l'ammissibilità al finanziamento, dovrai effettuare
                      l'invio della domanda nel modo più accurato e nel minor
                      tempo possibile in quanto solo i progetti inviati
                      correttamente per primi sul sito INAIL verranno ammessi al
                      contributo.
                    </p>
                  </Text>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </Flex>
      </Flex>
      <Flex
        width={{ base: "100%", lg: "50%" }}
        height={{
          base: "auto",
          lg: `calc(${windowHeight}px - ${theme.space[24]})`,
        }}
        color={theme.colors.white}
        direction={"row"}
        justifyContent={{ md: "center", lg: "left" }}
      >
        <Flex
          direction={"column"}
          width={{ base: "100%", xl: "560px", lg: "440px", md: "440px" }}
          margin={"auto 0 auto 0"}
          display={{ sm: "none", lg: "flex" }}
        >
          <Box textAlign={"center"}>
            <Image
              src="/assets/euro.svg"
              alt="Hand"
              margin={{
                base: `${theme.space[24]} 0`,
                lg: 0,
                sm: `${theme.space[24]} auto`,
              }}
              maxWidth={{ lg: `330px`, xl: `100%` }}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Comefunziona;
