import {
  Flex,
  Icon,
  IconButton,
  Image,
  useBoolean,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import { FiMenu, FiX } from "react-icons/fi";
import Item from "./HeaderItem";

type HeaderProps = {
  children?: React.ReactNode;
  scrollIdx: number;
  windowWidth: number;
  windowHeight: number;
  pageCase: number;
};

const Header: React.FC<HeaderProps> = ({
  scrollIdx,
  windowWidth,
  windowHeight,
  pageCase,
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useBoolean(false);

  if (windowWidth < 768) {
    // Mobile menu
    return (
      <Flex
        as="header"
        align="center"
        width={"100vw"}
        height={isOpen ? windowHeight : theme.space[20]}
        backgroundColor={
          isOpen
            ? theme.colors.brand.accentSecondary
            : scrollIdx === 0 || scrollIdx === 1 || scrollIdx === 2
            ? theme.colors.brand.primary
            : theme.colors.brand.primary
        }
        color={theme.colors.white}
        position={"fixed"}
        direction={"column"}
        zIndex={"1300"}
      >
        <Flex
          width={"100%"}
          height={theme.space[20]}
          align="center"
          padding={theme.space[4]}
        >
          <Flex width={"80%"} justifyContent={"flex-start"}>
            <Image src="/assets/logo.svg" alt="Logo" />
          </Flex>
          <Flex width={"20%"} justifyContent={"center"}>
            <IconButton
              aria-label="Open menu"
              onClick={setIsOpen.toggle}
              icon={
                <Icon
                  as={isOpen ? FiX : FiMenu}
                  boxSize={"2.5em"}
                  color={theme.colors.white}
                />
              }
              size="lg"
              variant={"unstyled"}
            />
          </Flex>
        </Flex>
        {isOpen && (
          <Flex
            width={"100%"}
            height={`calc(${windowHeight}px - ${theme.space[20]})`}
            direction={"column"}
            justifyContent={"space-around"}
            padding={`${theme.space[8]} ${theme.space[4]}`}
          >
            <Item key="/" to="/" label="Home" />
            <Item
              key="/esperienza"
              to="/esperienza"
              label="L'esperienza Click Day"
            />
            <Item key="/bando" to="/bando" label="Il Bando Inail" />
            <Item key="/chisiamo" to="/chisiamo" label="Chi Siamo" />
            <Item key="/faq" to="/faq" label="Domande Frequenti" />
            <Item key="/dashboard" to="/dashboard" label="Area Personale" />
          </Flex>
        )}
      </Flex>
    );
  }

  return (
    <Flex
      as="header"
      align="center"
      wrap="wrap"
      width={"100vw"}
      height={theme.space[24]}
      color={theme.colors.white}
      backgroundColor={
        scrollIdx === 0 || scrollIdx === 1 || scrollIdx === 2
          ? pageCase === 1
            ? theme.colors.brand.accent
            : "transparent"
          : theme.colors.brand.primary
      }
      transition={"background-color 1000ms linear"}
      position={
        scrollIdx === 0 || scrollIdx === 1 || scrollIdx === 2
          ? "fixed"
          : "fixed"
      }
      zIndex={"1300"}
    >
      <Flex width={"40%"} justifyContent={"space-around"}>
        <Item key="/" to="/" label="Home" />
        <Item
          key="/esperienza"
          to="/esperienza"
          label="L'esperienza Click Day"
        />
        <Item key="/bando" to="/bando" label="Il Bando Inail" />
      </Flex>
      <Flex width={"20%"} justifyContent={"center"}>
        <Image src="/assets/logo.svg" alt="Logo" />
      </Flex>
      <Flex width={"40%"} justifyContent={"space-around"}>
        <Item key="/chisiamo" to="/chisiamo" label="Chi Siamo" />
        <Item key="/faq" to="/faq" label="Domande Frequenti" />
        <Item key="/dashboard" to="/dashboard" label="Area Personale" />
      </Flex>
    </Flex>
  );
};

export default Header;
